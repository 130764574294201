export interface eventProps {
  action: string;
  accountId: string;
  storyId?: string;
  name?: string;
  firstName?: string;
  lastName?: string;
  userId?: string;
  email?: string;
  platform?: string;
  location?: string;
  storyUrl?: string;
  phoneNumber?: string;
  form?: string;
  tos?: boolean;
  verificationCode?: string;
  page?: string;
  fileSize?: number;
  errorMessage?: string;
  campaignId?: string | null;
  source?: string;
}
export interface pageViewProps {
  url: string;
  accountId: string;
  userId?: string;
  email?: string;
  name?: string;
  storyId?: string;
}
// SEGMENT EVENT TRACK
export const trackSegmentEvent: (arg0: eventProps) => void = ({ action, accountId, ...props }) => {
  window.analytics.track(action, { ...props, accountId });
};

// SEGMENT TRACK LINK OPTIONAL??
export const segmentTrackLink = (id: string, eventName: string, accountId: string) => {
  var link = document.getElementById(id);

  window.analytics.trackLink(link, eventName, { accountId });
};

// GA4 EVENT TRACK
const trackGAEvent: (arg0: eventProps) => void = ({ action, ...props }) => {
  window.gtag('event', action, {
    video_id: props.storyId || null,
    ...props,
  });
};
// GLOBAL TRACK PAGE VIEW
export const trackPageView: (arg0: pageViewProps) => void = ({ url, storyId, accountId, userId, email, name }) => {
  (global as any).analytics.page(url, {
    accountId,
    storyId,
    userId,
    email,
    name,
  });
};
// GLOBAL EVENT TRACK
export const trackEvent: (arg0: eventProps) => void = ({ action, accountId, ...props }) => {
  if (process.env.APP_ENV === 'development') {
    console.log(`%c tracked ${action}`, 'background: #222; color: yellow');
  }
  // trackSegmentEvent({ action, accountId, ...props });
  const formattedAction = action.replace('-> ', '').replace(/ /g, '_');
  trackGAEvent({ action: formattedAction, accountId, ...props });
};

export const trackAllEvents: (arg0: eventProps) => void = ({ action, ...eventProps }) => {
  window.analytics.track(action, {
    accountId: eventProps.accountId,
    storyId: eventProps.storyId,
    name: eventProps.name,
    userId: eventProps.userId,
    email: eventProps.email,
    platform: eventProps.platform,
    location: eventProps.location,
    storyUrl: eventProps.storyUrl,
    phoneNumber: eventProps.phoneNumber,
    form: eventProps.form,
    tos: eventProps.tos,
  });
};
