import Head from 'next/head';
import { FC } from 'react';

export interface HeaderProps {
  venueLogo?: string;
  metaOGUrl?: string;
  metaOGImg: string;
  metaOGVideo?: string;
  metaOGTitle: string;
  metaOGDescription: string;
  metaTwitterImg: string;
  metaTwitterTitle: string;
  metaTwitterDescription: string;
  metaTwitterSiteId: string;
  title?: string;
  accountId: string;
}

export const Header: FC<HeaderProps> = ({
  children,
  venueLogo,
  metaOGTitle,
  metaOGDescription,
  metaOGUrl,
  metaOGImg,
  metaOGVideo,
  metaTwitterImg,
  metaTwitterTitle,
  metaTwitterDescription,
  metaTwitterSiteId,
  title,
  accountId,
}) => {
  const favicon = venueLogo || '/favicon.ico';
  return (
    <Head>
      {/* Primary Meta Tags */}
      <script src="https://www.googletagmanager.com/gtag/js?id=G-P5BPYGJNER" />
      <script
        dangerouslySetInnerHTML={{
          __html: `
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', 'G-P5BPYGJNER', {
      account_id: "${accountId}"
    });
  `,
        }}
      />
      <script src="https://cmp.osano.com/AzyfddTRtxW3j66kW/265d094c-3705-40be-82da-cd4ec46e92d8/osano.js"></script>
      <title>{title || metaOGTitle}</title>
      <meta httpEquiv="Cache-Control" content="no-cache, no-store, must-revalidate" />
      <meta httpEquiv="Pragma" content="no-cache" />
      <meta httpEquiv="Expires" content="0" />
      <meta name="title" content={metaOGTitle} />
      <meta name="description" content={metaOGDescription} />

      {/* Open Graph / Facebook */}
      <meta property="og:type" content="website" />
      <meta property="og:url" content={metaOGUrl} />
      <meta property="og:title" content={metaOGTitle} />
      <meta property="og:image" content={metaOGImg} />
      <meta property="og:image:width" content="600" />
      <meta property="og:image:height" content="314" />
      <meta property="og:description" content={metaOGDescription} />

      {metaOGVideo && <meta property="og:video" content={metaOGVideo} />}
      {metaOGVideo && <meta property="og:video:width" content="360" />}
      {metaOGVideo && <meta property="og:video:height" content="640" />}

      {/* Twitter */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site:id" content={metaTwitterSiteId} />
      <meta name="twitter:title" content={metaTwitterTitle || title} />
      <meta name="twitter:description" content={metaTwitterDescription} />

      <meta name="twitter:image" content={metaTwitterImg} />
      {/* Favicon */}
      <link rel="icon" href={favicon} />
      {/* an attempt to avoid screen zooming in on some input fields when focused for iphones */}
      <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0" />
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap" rel="stylesheet" />
      {children}
    </Head>
  );
};
