export enum UploaVideoStrings {
  ConfirmEmail = 'Confirm your email to access reward',
  AddNumber = 'Add your number so we can ...',
  AddSocialHandle = 'Add your social hanlde so we can promote you',
  AddPassword = 'Add your password so you can earn points',
}

export enum EmailPopupStrings {
  TitleFirstText = 'Become a',
  TitleSecondText = 'Hero',
  BenefitsListEarn = 'Receive rewards and discount for favorite products',
  BenefitsListOffers = 'Tell your story to help drive donations to Concord High',
  BenefitsListOffersFreeItems = 'Earn rewards set by your coach',
  HowItWorks = 'How it works',
  ToFlow = 'Get Started',
  EmailLabel = 'Your E-mail',
  BenefitsListTellFriends = 'Tell your story to friends and family',
  BenefitsShare = 'Have them help you share it',
}

export enum SideBarStrings {
  Home = 'Create Video',
  Rewards = 'Rewards',
  Create = 'Create',
  MyVideos = 'My Clips',
  Goals = 'Goals',
  YourVideos = 'Your Videos',
  Profile = 'My Profile',
  ActivityHistory = 'Activity History',
  Register = 'Sign Up / Login',
  HowItWorks = 'How It Works',
  EmailUs = 'Email Us',
  LiveChat = 'Need help? Live chat with us',
  Terms = 'Terms of Use',
  Logout = 'Logout',
}

export enum ReferencesPageStrings {
  TitleFirstText = 'Choose your',
  TitleSecondText = 'Video',
}

export enum ResetPasswordPageStrings {
  TitleFirstText = 'Reset',
  TitleSecondText = 'Password',
  SubmitButton = 'Update Password',
}

export enum AuthFormStrings {
  Name = 'Name',
  FirstName = 'First Name',
  LastName = 'Last Name',
  Email = 'Your E-mail',
  InstagramHandleOptional = 'Your Instagram (optional)',
  InstagramHandle = 'Your Instagram',
  Password = 'Password*',
  NewPassword = 'New Password',
  ConfirmPassword = 'Confirm Password',
  ResetPassword = 'Reset Password',
  ForgotPassword = 'Forgot Password?',
  AcceptThe = 'By proceeding, you agree to our',
  IAgreeToThe = 'I Agree to the ',
  OptTextSMS = ' and \n Opt-In to receive future SMS messages',
  TermsOfService = 'Terms of Service',
  PrivacyPolicy = ' Privacy Policy',
  CheckToContinue = 'To continue, you must check the box above',

  PhoneNumber = 'Your Cell Phone*',
  EnterYourCellPhone = 'Enter Your Cell Phone*',

  PrivacyPolicyText1 = 'By continuing you agree to our ',
  PrivacyPolicyText2 = 'Terms of Use',
  Processing = 'Processing...',
  GetAccountLink = 'Get your account verification link',
  DontCloseText = 'Don’t close this browser tab while your video is uploading!',
  Welcome = 'Welcome',
  EnterDetails = 'Welcome! Please enter your details.',
  MessageRate = 'Message data rate may apply',
  EnterYourName = 'Enter your first and last name',
  EnterYourFirstName = 'Your First',
  EnterYourLastName = 'Your Last',
  WellSentYouCode = ' We’ll text you a verification code after you click send.',
}

export enum SignInPageStrings {
  TitleFirstText = 'Login in',
  TitleSecondText = 'Melin',
  UseNameLabel = 'Name',
  EmailLabel = 'Email',
  SignUp = 'Sign Up',
  Password = 'Password',
  Login = 'Login',
  RedirectedText = 'Redirect to sign up form',
}

export enum SignUpPageStrings {
  TitleFirstText = 'Become a',
  TitleSecondText = 'Melin Hero',
  UseNameLabel = 'Name',
  EmailLabel = 'Email',
  SignUp = 'Sign Up',
  Password = 'Password',
  SubmitButton = 'Join now',
  Login = 'Login',
  RedirectedText = 'Redirect to sign up form',
  ForRewards = 'For Rewards',
  ForYourVideos = 'For your video to be showcased.',
  ToReceiveText = 'To receive a text when your video is ready to share.',
}

export enum AuthPopupStrings {
  TitleFirstText = 'Become a',
  TitleSecondText = 'Hero',
  SignUpFirstText = 'Create',
  SignUpSecondText = 'Account',
  Password = 'Password',
  SubmitButton = 'Next',
  Send = 'Send',
  SignUp = 'Create Account',
  SignUpSubmitButtonName = 'Join now',
  LogIn = 'Login',
}

export enum SharePageStrings {
  Title = 'Every share gets you closer to your reward',
  TitleFirstText = 'Share with',

  TitleSecondText = 'Friends / Followers',
  DownloadButtonText = 'Download',
  MyProfileBtn = 'My Profile',
  CopyLinkButtonText = 'Copy Link',
  CopiedLinkButtonText = 'Link Copied',
  ShareVideoHint = 'Instagram, Snapchat, Tiktok',
  ShareLinkHint = 'Facebook, Whatsapp, SMS',
  BackDropHint = 'Your clip will be accessible here',
  DownloadPopupText = 'To download your clip copy link and paste it in the Safari browser',
  SmsButtonText = 'Share via Text Message',
  EmailButtonText = 'Share via Email',
  FacebookButtonText = 'Share via Facebook',
  LinkedinButtonText = 'Share via LinkedIn',
  TwitterButtonText = 'Share via Twitter',
  InstagramButtonText = 'Share to bio',
  InstagramPreviewTitle = 'Add your clip link to profile Website field:',
  InstagramBio = 'Help my team, click on the link:',
  InstagramBioEditBtn = 'Edit Profile',
  SmsMessage = 'How is the season going?',
  SmsTitle = 'Your clip message',
  SmsMessageTime = 'Seen 1:30pm',
  VideoCreatedPopupOnce = 'First Clip Earned You',
  VideoCreatedPopupOther = 'This Clip Earned You',
  RewardsPointsModalBtn = 'Earn more points',
  Points = 'points',
  Congrats = 'Congrats!',
  CopyThumbnail = 'Copy Clip Tumbnail',
  ThumbnailCopied = 'Copied - Paste into email body',
  SMSThumbnailCopied = 'Copied - Paste into SMS',
  InstagramLinkCopied = 'Link copied',
  ShareText = 'Share via Text Message',
  ShareEmail = 'Share Via Email',
  ShareAsText = 'Share to text message',
  ShareAsEmail = 'Send as email message',
  ClickLinkBelow = 'Click link below',

  OverlayText = 'Overlay Text',
  IntroText = 'Intro text',
  VideoMessage = 'Share Message',
  ButtonLink = 'Button Link',
  ButtonText = 'Button Text',
  VideoText = 'Clip Text',
  VideoTitle = 'Clip Title',
  PostMessage = 'Post Message',
  VideoTextTooltip = 'Add a personalized message that appears at the top of your clip thumbnail when shared',
  VideoTitleTooltip = 'Add a title to your clip that will appear with your clip preview when shared',
  ButtonTextTooltip = 'Use button text that answers the question “Why should I click this?”',
  ButtonLinkTooltip = 'Add a valid “https://” url you want your clip button to direct the viewer',
  Thumbnail = 'Thumbnail',
  Public = 'Public',
  Cancel = 'Cancel',
  Save = 'Save',
  PreviewTitle = 'The World',
  PreviewTitlept2 = 'Share To',
  PreviewSubtitle = 'Clip perfomance is tracked',
  ShareWith = 'Share With',
  Share = 'Share',
  ShareNow = 'Share Now',
  NewContactText = `‘New Contact’ means you have never exchanged messages before.`,
  MycontactsText = '‘My contact’ means you have exchanged messages before.',
  WhereDownloading = 'Where are you downloading?',
  HowToDownload = 'How to download your clip',
  RetakeVideo = 'Retake Clip',
  SubmitVideo = 'Submit Clip',
  EditThumbnail = 'Edit Thumbnail',

  ShareVia = 'Share via ',
  ShareTextMessage = 'Share via SMS',
  OpenTextMessage = 'Open SMS',

  cta_copyLinkClicked = 'Clip Link Copied',
  cta_copyLink = 'Copy My Clip link',
  cta_linkedin = '',

  SendInfoTitle = 'We will send you a text...',
  SendInfoText = 'after we’ve reviewed your clip.',
  VideoUploaded = 'Clip Uploaded',
  Verified = 'Verified',
  VideoReceived = 'Clip Received',
  WhatToDoTitle = 'What would you like to do next?',
  YourProfile = 'Your Profile',
  ShareVideo = 'Share Clip',
  ShareYourVideo = 'Share Your Clip',
  LinkTo = 'Link To:',
  TapToClose = 'Tap To Close',
  CopyLink = 'Copy URL Link',
  LinkCopied = 'URL Link Copied!',
  UploadInfoAwareness = 'Everyone here at [Company Name] is grateful for your loyalty, [First Name]!',
  UploadInfoIncentiveAuto = 'We will contact you via text about getting your [Reward Name] very soon.',
  UploadInfoIncentiveManual = 'We will be in contact with you via text about getting your [Reward Name]',
  DownloadVideo = 'Download Clip',

  DownloadTitle = 'Where do you intend to post?',

  YourVideoReceived = 'Your Video was Received',
  YouAreAllSet = 'You’re all set, {name}!',
  ThankYou = 'Thank you for sharing \n your experience with \n {name}.',
  WouldYouShareVideo = 'Would you like to share your video \n on your own social media?',
}

export enum SafariIosDownloadHelperStepsStrings {
  step1 = '1. Tap on the Download Your Video button',
  step2 = '2. Tap "Download" when prompted to',
  step3 = '3. Next to URL, tap on the ',
  step4 = '4. After video is downloaded, tap on it',
  step5 = '5. Tap the ',
  step6 = '6. Tap “Save Video” to save to your phone ',
  icon = ' icon',
  shareOtions = 'icon to bring up share options',
}

export enum ChromeIosDownloadHelperStepsStrings {
  step1 = '1. Tap on the Download Your Video button',
  step2 = '2. Tap on the “',
  step3 = '3. Tap on “',
  step4 = '4. Tap on your video to view it',
  step5 = '5. Tap the ',
  step6 = '6. Tap “Save Video” to save video to your phone',
  shareOtions = 'icon to bring up share options',
  openInDownloads = 'Open in Downloads',
  option = '” option',
  openIn = 'Open in...',
  downloadTab = '” on the download tab',
}

export enum BottomBarStrings {
  PoweredBySocialVenu = 'Powered by SocialVenu',
  Terms = 'Terms of Use',
}

export enum TermsOfServiceStrings {
  Text = 'You agree to our ',
  Link = 'Terms & Conditions',
  TitleFirstText = 'Terms of',
  TitleSecondText = 'Service',
  TermsOfService = 'User Terms',
  PrivacyPolicy = 'Privacy Policy',
}

export enum BottomNavStrings {
  menu = 'Menu',
  myVideos = 'My Clips',
  create = 'Create',
  rewards = 'Reward',
  rewardMenu = 'Points',
  goal = 'Goal',
}

export enum reviewPageStrings {
  headlinePrefix = 'Preview',
  headlineSuffix = 'Video',
  subheading = 'Do you like the video you used?',
  ctaLeft = 'Retake Video',
  ctaRight = 'Submit My Video',
}

export enum MediaListStrings {
  PrivateVideo = 'Private Video',
  PublicVideo = 'Public Video',
  ConfirmDialogText = 'Do you really want to make this video private?',
  ShareViaSocials = 'Share via Socials',
  ConfirmButton = 'Confirm',
  DuplicateVideo = 'Duplicate Video',
  TitleText = 'Title text',
  SaveBtn = 'Save',
}

export enum VideoToolStrings {
  My = 'My',
  Submit = 'Submit',
  SubmitVideoCreateAnother = 'Submit the video or create another',
  GoNextCreateAnotherVideo = 'Go next or create another video',
  TryAgain = 'Try Again',
  Video = 'Video',
  ReRecord = 'Try Again',
  KeepText = 'Keep it below 30 seconds',
  RecordVideo = 'Record A Clip',
  UploadAVideo = 'Upload A clip',
  RecordUploadVideo = 'Record/Upload a clip',
  Recording = 'RECORDING...',
  GetReady = 'GET READY...',
  SubmitVideo = 'SUBMIT CLIP?',
  Retake = 'Retake',
}

export enum ForgotPasswordStatusPageStrings {
  TitleFirstText = 'Reset Email',
  TitleSecondText = 'Sent!',
  ExplainedText = 'If an account exists for <{{email}}>, you will get an email with instructions on resetting your password.',
  ExplainedTextExtra = 'If it doesn’t arrive, be sure to check your spam folder.',
  Btn = 'Back to Log in',
}

export enum ForgotPasswordPageStrings {
  TitleFirstText = 'Forgot',
  TitleSecondText = 'Password',
  ExplainedText = 'Enter the email address you used when you joined and we’ll send you instructions to reset your password.',
}

export enum HistoryRewardsPageStrings {
  TitleSecondText = 'Activity History',
}

export enum HowItWorksPageStrings {
  TitleFirstText = 'How it',
  TitleSecondText = 'Works',
}

export enum CommonStrings {
  Points = 'Points',
}

export enum StoryPageStrings {
  Subtitle = 'Share your experiences with us',
  Description = 'Create your profile to get started',
  MainText = 'Your videos mean the world to us!',
  MainTextDescription = 'Upload a video and share it with your friends.',
  TitleFirstText = 'Add your',
  TitleSecondText = 'Video',
  ChangeStory = 'Change Video',
  UploadButton = 'Upload Video',
  RecordButton = 'Record Video',
  ExplanationAboutContent = 'Where does my content go?',
  Donate = 'Donate',
  PointsEarned = '+100 Points',
  CaptureUploadVideo = 'Submit a video',
  PreparingVideo = 'Preparing your video...',

  uploading_titleComplete = 'Completed',
  uploading_title = 'Uploading',
  uploading_cta_duringUpload = 'Please wait...',
  uploading_cta_afterUpload = 'Share My Video Link',
  FinalizingSubmission = 'Finalizing Submission',
}

export enum CreateVideoPageStrings {
  Title = 'Submit Your Video',
  Subtitle = 'Try to keep it under 20 Seconds.',
  Description = `Tap on camera to record or
  Upload a video`,
  UploadVideo = `Upload or Record your video clip here`,
  UploadVideoVideoLength = `Recommended length: 30 sec`,
  Brush2 = 'Have Some Fun!',
  Brush3 = 'Tell Us Your Story!',
  Brush4 = 'Be Yourself!',
  Brush5 = 'You’re The Star!',
}

export enum ProfilePageStrings {
  TitleFirstText = 'Profile',
  UseNameLabel = 'Name',
  EmailLabel = 'Your E-mail',
  Password = 'Password',
  SubmitButton = 'Save',
  Rewards = 'Rewards',
  RewardsProgressBarTitle = 'Points',
  PointsHistoryBtn = 'Activity History',
  Redeem = 'Redeem',
  GetReward = 'Get A Reward',
  WarningTitle = 'Wanna get this Brand T-Shirt?',
  WarningText = 'Do you really want to exchange 300 points for a reward?',
  CongratulationTitle = 'Congratulation!',
  CongratulationText = 'We have received your request. Soon our manager will contact with you.',
  OtherWaysToEarn = 'Other Ways to Earn',
  LearnHowToEarn = 'Learn How To Earn',
  CreateStory = 'Create a Video',
  CreateAnotherStory = 'Create Video',
  IsNotVIdeoItems = 'Create a video, share and earn points',
  SaveButton = 'Save',
  ComingSoon = 'Coming soon',
  AccountTab = 'Account',
  VideosTab = 'Videos',
  RewardsTab = 'Rewards',
  ActivityHistoryTab = 'Activity History',
  FilterLabel = 'Video isPublic filter',
  All = 'All',
  Public = 'Public',
  Private = 'Private',
  SubmitMessage = 'Your video was submitted',
  ProfileTabFormTab1 = ' Profile',
  ProfileTabTextFormField1 = 'Video Title',
  ProfileTabTextFormField2 = 'Meta Description',
  ProfileTabTextFormField3 = 'Video Text',
  ProfileTabTextFormField4 = 'Button Text',
  ProfileTabTextFormField5 = 'Button Link',
  ProfileTabTextFormCtaBtn = 'Save',
  videos_shareEdit = 'Shares',
  videos_Engagements = 'Clicks',
  videos_Conversions = 'Conversions',
  videos_Points = 'Points',
  videos_Views = 'Views',
  videos_Date = 'Date',
  NoRewardCampaignLayoutTitle1 = 'Help spread the word!',
  NoRewardCampaignLayoutTitle2 = 'Support',
  NoRewardCampaignModalTitle = 'There is no active campaign...',
}

export enum RewardsPageStrings {
  YourPoints = 'Your points',
}

export enum ErrorsStrings {
  MaxLength100 = 'max length 100 symbols',
  EmailIsTaken = 'E-mail is taken',
  EmailIsNotValid = 'Please enter a valid email address',
  EmailOrPasswordNotValid = 'E-mail or password is not valid',
  PasswordAtLeast6 = 'Password must be at least 6 characters long',
  PasswordIncorrect = 'Incorrect Password',
  ConfirmPassword = 'Confirm password must be equal to password',
  Name = 'Name is required',
  Email = 'E-mail is required',
  Password = 'Password is required',
  ConfirmPasswordRequired = 'Confirm password is required',
  Instagram = 'Instagram is required',
  UploadedFileHuge = 'You are trying to upload a big video file',
  InvalidToken = 'This invite link has expired. Check your email for a more recent invite sent to you',
  FileSizeExceeded = 'Unfortunately, we cannot upload this video. Please upload the video under  400MB',
}

export enum JoinNow_LoginStrings {
  TopTextTitle = 'Video Creator',
  TopTextSubTitle = 'Earn Points & Redeem Rewards',
  BaseButtonJoinNow = 'Join now',
  BaseButtonLogin = 'Login',
  AlreadyAUser = 'Already a user?',
  Login = 'Login',
  NeedAnAccount = 'Need an account? ',
  JoinNow = 'Join now',
  ForgotPassword = 'Forgot Password?',
  SendTextLink = 'Text Activation Link',
  SendLoginLink = 'Send Login Link',
  CreateYourVideo = 'Create Your Clip',
  SendLoginCode = 'Send Login Code',
  AccessYourAccountTitle = 'Access your account',
  BecomeCreatorTitle = 'Become a Creator',

  TOSAgreementText = 'By proceeding, you agree to our',
  TOSAgreementTextTerms = 'Terms of Use',
  TOSAgreementTextPolicy = 'Privacy Policy',
  DontCloseBrowser = `Don’t close this browser tab while 
  your video is uploading!`,
  VerifyItsYou = 'Verify It’s You',
  WellTextCode = 'We’ll text a verification code to your cell phone number.',
}

export enum NotAvailableStrings {
  Title = 'Video Not Available',
  Text = 'This video is currently being reviewed for violating our terms & conditions.  Check back later.',
}

export enum SortingStrings {
  DateAdded = 'Date Added',
  Title = 'Title',
  Points = 'Points',
  Shares = 'Shares',
}

export enum HowToEarnPointsStrings {
  HowToEarnPointsStrings = 'How To Earn',
  Activities = 'Activities',
  Points = 'Points',
  CreateVideo = 'Create Videos',
  CalltoAction = 'Clicks',
  Shares = 'Shares',
  BonusPoints = 'Bonus Points',
  Conversions = 'Conversions',
  Transactions = 'Transactions',
  CreateNewVideo = 'Create New Video',
  CreateVideoDescription = 'Awarded for each new video you create',
  SharesDescription = 'Awarded each time you share your video',
  CalltoActionDescription = 'Awarded for every unique click on your shared video',
  BonusPointsDescription = 'Awarded for the best content received',
  ConversionsDescription = 'Awarded when your referred visitors makes a purchase',
}

export enum VideoEmptyStateStrings {
  Title = 'Welcome to your Videos Page',
  Subtitle = 'Get started by creating a video!  All your videos will be displayed on this page',
  ButtonText = 'Create Your First Video',
}

export enum SmsModalStrings {
  Title = 'Access Your Account',
  Subtitle = 'You will receive a text message link to upload videos at:',
  DidntReceived = "Didn't receive message?",
  SendAgain = 'Send again',
  MessageResent = 'Message Resent!',
  Back = 'Back',
  TitleUnregistered = 'Invalid Number',
  SubtitleUnregistered = "The number you provided isn't correct.",
  ToAccessUnregistered = 'To access your account',
  EnterNewCell = 'Enter A New Cell Number',
}

export enum VerificationModalStrings {
  Title = 'Enter Verification Code',
  Subtitle = 'We’ve sent a text to ',
  DidntReceived = 'Didn’t get a code?',
  SendAgain = 'Click to resend',
  MessageResent = 'Message Resent!',
  VerifyAccount = 'Verify your cell',
  InvalidCode = 'Invalid Code. Please check and try again',
  TitleUnregistered = 'Oops, something went wrong',
  SubtitleUnregistered = 'We were unable to verify your number:',
  ToAccessUnregistered = 'To access your account',
  OptTextSMS = 'For some carriers this may take longer than others.',
  EnterNewCell = 'Enter A New Cell Number',
  Back = 'Back',
}

export enum DeactivatedModalStrings {
  Title = 'We’re sorry...',
  AccountDeactivated = 'This account has been deactivated by',
  Cancel = 'Cancel',
}

export enum CouponStrings {
  Congrats = 'CONGRATS',
  RewardCard = 'REWARD CARD',
  ThankYou = 'THANK YOU',
  DefaultName = 'Jenny',
  DefaultReward = '$25 Off Your Next Purchase',
  DefaultCardNumber = '2875 3563 3984 2764',
  DefaultFirstName = 'Jenny',
  DefaultLastName = 'Myles',
}

export enum UploadProgressStrings {
  VideoUploadInProgress = 'Clip upload in Progress...',
  VideoUploadComplete = 'Look for a text message',
  ThanksForUploading = 'after we’ve viewed your video',
  KeepThisTabOpen = 'Keep this tab open during upload',
  VideoUpload = 'Clip Upload',
  VideoReceived = 'Clip Received',
  NotCloseBrowser2 = '**Do not close browser tab**',
  VideoIsUploading = 'Are you in a hurry? We can text you when your clip has been uploaded.',
  MayTake = `This may take up to 15 minutes.
  Depending on the size of the clip.`,
  TextWhenReady = `For your convenience, we can text you when your clip is ready.`,
  LeaveTabOpen = `Leave your browser tab open, we’ll send
   you a text message when your clip 
   is finished uploading!`,
  YesSendMessage = 'Text me when my clip is ready.',
  SmsWillBeSent = 'Thank You!',
  RatesApply = 'Standard rates apply',
  Uploading = `Clip Upload in progress
**Do not close browser tab**`,
  EstimatedTime = 'ESTIMATED UPLOAD TIME',
  VideoUploadFailed = 'Clip Upload Failed',
  WeakConnectivityDetected = 'Weak connectivity detected',
  Hmmm = 'Hmmm...',
  SomethingWentWrong = 'It looks like something went wrong. ',
  RestartUpload = 'Would you like to restart upload?',
  RestartUploadButton = 'Restart Upload',
}

export enum PointsStrings {
  MyProgress = 'My Progress:',
  MyRewards = 'My Rewards:',
  ThanksForSubmitting = 'Thanks for submitting your clip(s)!',
  Eligibility = 'Eligibility for this reward is based on the number of videos uploaded',
}

export enum HowToRewardCreatorsStrings {
  Title = 'Customize Reward Incentive',
  RewardHeader = 'Reward Header',
  RewardName = 'Reward Name',
  PhoneNumber = 'Phone Number',
  SelectBackgroundImage = 'Select a Background image',
  PreviewInfoText = 'This is what the creator will see as part of their reward acknowledgement SMS message.',
  PreviewInfoTextTrublu = 'This is what the patient will see as part of their reward acknowledgement SMS message.',
  CustomizeReward = 'Customize Reward Incentive',
  RedeemText = 'Contact us at:',
  CreateMoreVideos = 'Create more videos!',
  VideosEligibility = 'Eligibility for this reward is based on the number of videos uploaded',
  ViewsEligibility = 'Eligibility for this reward requires views on uploaded videos',
  SharesEligibility = 'Eligibility for this reward requires both views & shares on uploaded videos',
  CollectMoreViews = 'Collect more views!',
  PostAndShare = 'Post and share!',

  VideosGoal = 'Upload more videos to reach your goal',
  ViewsGoal = 'Receive more views on your uploaded videos to reach your goal',
  SharesGoal = 'Receive more views & shares on your uploaded videos to reach your goal',
}

export enum BusinessProfilePageStrings {
  UploadAClip = 'Upload a Clip',
  Subtitle = 'Video Company in Oakland California',
  Website = 'Website',
  Directions = 'Directions',
  Address = 'Address:',
  AddressMobile = 'Address',
  RightsReserved = '© 2024 SocialV. All rights reserved.',
  ReviewSummary = 'Review Summary',
  Reviews = 'Reviews',
  MoreReviews = 'More Google Reviews',
  WriteAReview = 'Write a Review',
  VideoCompany = 'Video Company',
  LocationNotFound = 'Location not found',
}

export enum NavigationPanelStrings {
  Welcome = 'Welcome',
  Upload = 'Upload',
  Uploading = 'Uploading',
  Uploaded = 'Uploaded',
  Verify = 'Verify',
  Verified = 'Verified',
  Complete = 'Complete',
}

export enum SignUpScreenStrings {
  GetStarted = 'Lets get started!',
  Welcome = 'Welcome',
  UploadOrRecordVideo = 'Upload or record your video clip below',
  Name = `What's your name?`,
  First = 'First',
  Last = 'Last',
  UploadAClip = 'Upload a Clip',
  RecordClip = 'Record a Clip',
  FieldsRequired = 'The name fields are required',
  Accept = 'I accept the ',
  Ampersand = ' & ',
  TermsOfUse = 'Terms of Use',
  PrivacyPolicy = 'Privacy Policy',
  YouAgree = 'You agree to our ',
  CheckTheBox = 'To continue, you must check the box above',
  UserTerms = 'User Terms',
  ByProceeding = 'By proceeding, You agree to our ',
  WelcomeVideoScreen = 'Welcome video screen',
  SignUpScreen = 'Sign up screen',
  VerificationScreen = 'Verification screen',
  LaunchScreen = 'Launch screen',
  LetsGo = `LET’S GO!`,
}
